import ApiService from "./api.service";

export const getListTransports = async () => {
  return await ApiService.get("/transport?page=1&limit=1000");
};

export const getProvince = async () => {
  return await ApiService.get("/metadata/countries/th/subdivisions");
};

export const getDistrict = async (code = "th") => {
  return await ApiService.get(`/metadata/subdivisions/${code}/districts`);
};

export const getSubDistrict = async (code = "th") => {
  return await ApiService.get(`/metadata/districts/${code}/subdistricts`);
};

export const createProvince = async payload => {
  return await ApiService.post("/common/provinces", payload);
};
