<template>
<b-container>
 <div class="row pr-4 pl-4 pb-5">
    <!-- <div class="banner"> 
      <b-img :src="company && company.banners" class="img-fluid"></b-img>
      <img
        class="card-img-logo"
        :src="company && company.logo"
        :alt="company && company.name && company.name.th"
      />
    </div> -->
    <div class="content mb-5 pb-5 mb-5">
      <h3>{{ payload.title.th }} {{ payload.title.en }}</h3>
      <p>{{ company.name.th }}</p>
      <p>สร้างตำแหน่งงาน</p>
      <divider color="#53A2DA" :height="2"/>
      <b-row class="w-100 pb-5 mb-5">
        <b-colxx lg="12">
          <b-form @submit.prevent="onSubmit">
            <b-row>
              <b-colxx lg="6">
                <b-form-group> 
                  <label> ชื่องานที่รับสมัคร (ภาษาไทย)<span class="req">*</span></label>
                  <b-form-input type="text" v-model="payload.title.th" required/>
                </b-form-group>
              </b-colxx>
              <b-colxx lg="6">
                <b-form-group>
                  <label> ชื่องานที่รับสมัคร (ภาษาอังกฤษ)</label>
                  <b-form-input type="text" v-model="payload.title.en"/>
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-row>
              <b-colxx lg="6">
                <b-form-group> 
                  <label>จำนวนรับสมัคร (อัตราจ้าง)</label>
                  <b-form-input type="text" v-model="payload.vacancies" required/>
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-row>
              <b-colxx lg="6">
                <b-form-group> 
                  <label>คำอธิบายลักษณะงาน (ภาษาไทย) <span class="req">*</span></label>
                  <b-textarea
                    required
                    v-model="payload.descriptions.th"
                    :rows="4"
                    :max-rows="4"
                  />
                </b-form-group>
              </b-colxx>
              <b-colxx lg="6">
                <b-form-group> 
                  <label>คำอธิบายลักษณะงาน (ภาษาอังกฤษ)</label>
                  <b-textarea
                    v-model="payload.descriptions.en"
                    :rows="4"
                    :max-rows="4"
                  />
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-row>
              <b-colxx lg="6">
                <b-form-group> 
                  <label>คุณสมบัติผู้สมัคร (ภาษาไทย) <span class="req">*</span></label>
                  <b-textarea
                    required
                    v-model="payload.qualifications.th"
                    :rows="4"
                    :max-rows="4"
                  />
                </b-form-group>
              </b-colxx>
              <b-colxx lg="6">
                <b-form-group> 
                  <label>คุณสมบัติผู้สมัคร (ภาษาอังกฤษ)</label>
                  <b-textarea
                    v-model="payload.qualifications.en"
                    :rows="4"
                    :max-rows="4"
                  />
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-row>
              <b-colxx lg="6">
                <b-form-group> 
                  <label>สถานที่ทำงาน อำเภอ/จังหวัด (ภาษาไทย) <span class="req">*</span></label>
                  <b-form-input type="text" v-model="payload.place.th" required/>
                </b-form-group>
              </b-colxx>
              <b-colxx lg="6">
                <b-form-group> 
                  <label>สถานที่ทำงาน อำเภอ /จังหวัด (ภาษาอังกฤษ)</label>
                  <b-form-input type="text" v-model="payload.place.en" />
                </b-form-group>
              </b-colxx>
            </b-row>
             <b-row>
              <b-colxx lg="6">
                <b-form-group> 
                  <label>Latitude</label>
                  <b-form-input type="text" v-model="payload.location.latitude" required/>
                </b-form-group>
              </b-colxx>
              <b-colxx lg="6">
                <b-form-group> 
                  <label>Longitude</label>
                  <b-form-input type="text" v-model="payload.location.longitude" />
                </b-form-group>
              </b-colxx>
            </b-row>

            <b-row>
              <b-colxx lg= "6">
                <b-form-group>
                  <label>เงินเดือน</label>
                  <b-input-group class="mb-2">
                    <!-- <b-dropdown text="Dropdown" variant="outline-secondary" >
                      <b-dropdown-item v-for="(type, index) in salaryType" :key="index">{{type.label}}</b-dropdown-item>
                    </b-dropdown> -->
                    <v-select
                      v-model="salary"
                      :options="salaryType"
                      :reduce="(option) => option.type" label="label"
                      required
                    />
                    <b-form-input
                      v-model="salaryRange1"
                      aria-label="Value"
                      :disabled="payload.salary.type =='none'"
                      required
                    ></b-form-input>
                    <p v-if="salary=='range'" class="m-auto pl-2 pr-2"> - </p>
                    <b-form-input
                      v-if="salary=='range'"
                      v-model="salaryRange2"
                      aria-label="Value"
                    ></b-form-input> 
                    <v-select
                      v-model="payload.salary.currency"
                      :options="['THB']"
                    />
                  </b-input-group>
                </b-form-group>
              </b-colxx>
              <b-colxx lg="6">
                <b-form-group>
                  <label>ประเภทการจ้างงาน</label>
                   <v-select
                    multiple
                    v-model="payload.employmentTypes"
                    :options="employmentTypes"
                    :reduce="(option) => option.value"
                    label="label"
                  />
                  <!-- <b-form-tags 
                    input-id="tags-basic" 
                    v-model="payload.employmentTypes"
                    placeholder="เพิ่มแท็ก"
                  ></b-form-tags> -->
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-row>
              <b-colxx lg="6">
                <label>สายงาน</label>
                <b-form-group>
                  <v-select
                    required
                    v-model="payload.jobCategory"
                    :options="categoriesOption"
                    :reduce="option => option.value"
                  />
                </b-form-group>
              </b-colxx>
              <b-colxx lg="6">
                <b-form-group>
                  <label>ระบบขนส่งสาธารณะที่เดินทางถึงสถานที่ทำงาน</label>
                  <v-select
                    multiple
                    v-model="payload.transports"
                    :options="transportsOption"
                    :reduce="(option) => option"
                    label="label"
                  />
                </b-form-group>
              </b-colxx>
            </b-row>
            <br>
            <p>ข้อมูลผู้ติดต่อ</p>
            <divider color="#53A2DA" :height="2"/>
            <b-row>
              <b-colxx lg="6">
                <b-form-group> 
                  <label>ชื่อผู้รับสมัคร หรือแผนกรับสมัคร (ภาษาไทย) <span class="req">*</span></label>
                  <b-form-input type="text" v-model="payload.contact.name.th" required/>
                </b-form-group>
              </b-colxx>
              <b-colxx lg="6">
                <b-form-group> 
                  <label>ชื่อผู้รับสมัคร หรือแผนกรับสมัคร (ภาษาอังกฤษ)</label>
                  <b-form-input type="text" v-model="payload.contact.name.en" />
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-row>
              <b-colxx lg="6">
                <b-form-group> 
                  <label>Email</label>
                  <b-form-input type="email" v-model="payload.contact.email" required/>
                </b-form-group>
              </b-colxx>
              <b-colxx lg="6">
                <b-form-group> 
                  <label>หมายเลขโทรศัพท์</label>
                  <b-form-input type="text" v-model="payload.contact.phone" />
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-row>
              <b-colxx lg="6">
                <b-form-group> 
                  <label>ประเทศ</label>
                  <v-select
                    autocomplete="off"
                    v-model="address.country.th"
                    :options="countryOption"
                    :reduce="(option) => option.value"
                  />
                </b-form-group>
              </b-colxx>
              <b-colxx lg="6">
                <b-form-group> 
                  <label>จังหวัด</label>
                  <v-select
                    autocomplete="off"
                    v-model="address.province.th"
                    :options="provinceOption"
                    :reduce="(option) => option.value"
                  />
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-row>
              <b-colxx lg="6">
                <b-form-group> 
                  <label>อำเภอ / เขต</label>
                  <v-select
                    autocomplete="off"
                    v-model="address.district.th"
                    :options="districtOption"
                    :reduce="(option) => option.value"
                  />
                </b-form-group>
              </b-colxx>
              <b-colxx lg="6">
                <b-form-group> 
                  <label>ตำบล</label>
                  <v-select
                    autocomplete="off"
                    v-model="address.subdistrict.th"
                    :options="subdistrictOption"
                    :reduce="(option) => option.value"
                  />
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-row>
              <b-colxx lg="6">
                <b-form-group> 
                  <label>รหัสไปรษณีย์</label>
                  <b-form-input type="text" v-model="address.zipcode" />
                </b-form-group>
              </b-colxx>
            </b-row>
            <b-button type="submit" size="md" variant="primary" class="mt-4 mr-3">
              บันทึก
            </b-button>
            <b-button
              size="md" 
              type="button"
              variant="danger"
              class="mt-4"
              @click="initPayload"
            >
              ล้าง
            </b-button>
          </b-form>
        </b-colxx>
      </b-row>
    </div>
  </div>
</b-container>
</template>

<script>
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import Divider from "@/components/Divider";
import { getListTransports } from "@/services/common.service";
import { createJob, getJobCategory } from "@/services/job.service";
import { getProvinces, getDistricts, getSubDistricts, multiLingualAddress } from "@/services/address.service";

export default {
  components: {
    Divider,
    "v-select": vSelect,
  },
  data() {
    return {
      company: null,
      payload: null,
      transports: [],
      provinces: [],
      districts: [],
      subdistricts: [],
      jobCategories: [],
      counrty: [],
      salaryType: [{
        label: 'ไม่ระบุ', type: 'none',
      },{
        label: 'ตามโครงสร้างบริษัท', type: 'structure',
      },{
        label: 'ระบุจำนวนเต็ม', type: 'expect',
      },{
        label: 'ระบุช่วง', type: 'range',
      }],
      employmentTypes: [
        { label: 'Full-Time', value: 'fulltime'},
        { label: 'Freelance', value: 'freelance'},
        { label: 'ฝึกงาน', value: 'internship'},
        { label: 'Part-Time', value: 'parttime'},
        { label: 'เด็กจบใหม่', value: 'newgrad'},
        { label: 'ระบุสัญญาจ้าง', value: 'contract'},
      ],
      salaryRange1: 0,
      salaryRange2: 0,
      salary: 'ไม่ระบุ',
      address: {
        address: {
          th: "",
          en: ""
        },
        subdistrict: {
          th: "",
          en: ""
        },
        district: {
          th: "",
          en: ""
        },
        province: {
          th: "",
          en: ""
        },
        zipcode: "",
        country: {
          th: "ไทย",
          en: "Thailand"
        },
      },
    }
  },
  created() {
    this.init()
  },
  watch: {
    salary() {
      if(this.salary == 'ไม่ระบุ') {
        this.payload.salary.type = 'none'
      }
      else {
        this.payload.salary.type = this.salary
      }
      if(this.payload.salary.type == 'none') {
        this.payload.salary.value = 0
      }
    },
    salaryRange1() {
      if (this.salary !== 'range') {
        this.payload.salary.value = this.salaryRange1
      }
    },
    salaryRange2() {
      this.payload.salary.value = [this.salaryRange1, this.salaryRange2]
    },
    'address.province.th'() {
      this.loadDistrictData()
    },
    'address.district.th'() {
      this.loadSubDistrictData()
    },
    'address.subdistrict.th'() {
      let subdistrict = this.address.subdistrict.th || ''
      let findZipcode = this.subdistricts.find((item) => item.subDistrict == subdistrict);
      this.address.zipcode = findZipcode && findZipcode.zipcode || this.address.zipcode;
    },
  },
  computed: {
    transportsOption() {
      return Object.keys(this.transports).map((key) => {
        return {
          label: `[${this.transports[key].type}] ${this.transports[key].value.code} ${this.transports[key].value.name.th}`,
          ... this.transports[key],
        };
      });
    },
    countryOption() {
      return [{ label: 'Thailand', value: 'Thailand'}];
    },
    provinceOption() {
      return this.provinces.map(value => {
        return { label: value, value };
      })
    },
    districtOption() {
      return this.districts.map(value => {
        return { label: value, value };
      })
    },
    subdistrictOption() {
      return this.subdistricts.map(value => {
        return { label: value.subDistrict, value: value.subDistrict };
      })
    },
    categoriesOption() {
      return this.jobCategories.map(cat => {
        return { label: cat.th, value: cat.th };
      });
    },
  },
  methods: {
    async init() {
      this.loadCompany()
      this.initPayload()
      await this.loadListTransports()
      await this.loadProvinceData();
      await this.loadJobCategoryData();
      if(!this.company) {
        window.location.href = "/";
      }
    },
    loadCompany() {
      const user = localStorage.getItem("user_info") && JSON.parse(localStorage.getItem("user_info"))
      if(!user || !user.company) {
        window.location.href = "/";
      }
      this.company = user.company
    },
    initPayload() {
      this.initAddress();
      this.payload = {
        title: {
          th: "",
          en: "",
        },
        descriptions: {
          th: "",
          en: "",
        },
        qualifications: {
          th: "",
          en: "",
        },
        place: {
          th: "",
          en: "",
        },
        vacancies: 0,
        location: {
          latitude: 0,
          longitude: 0,
        },
        contact: {
          name: {
            th: "",
            en: "",
          },
          email: "",
          phone: "",
        },
        salary: {
          type: "none",
          value: 0,
          currency: "THB",
        },
        employmentTypes: [],
        jobCategory: "",
        transports: [],
        status: "openings",
        // company: { _id: this.company._id },
      }
    },
    initAddress() {
      this.address = {
        address: {
          th: "",
          en: ""
        },
        subdistrict: {
          th: "",
          en: ""
        },
        district: {
          th: "",
          en: ""
        },
        province: {
          th: "",
          en: ""
        },
        zipcode: "",
        country: {
          th: "ไทย",
          en: "Thailand"
        },
      }
    },
    async loadProvinceData() {
      this.provinces = await getProvinces();
    },
    async loadDistrictData() {
      if (this.address.province) {
        this.districts = await getDistricts(this.address.province.th || '');
      }
    },
    async loadSubDistrictData() {
      if (this.address.province || this.address.district) {
        this.subdistricts = await getSubDistricts(this.address.province.th || '', this.address.district.th || '');
      }
    },
    async loadListTransports() {
      const { data } = await getListTransports();
      this.transports = data.data;
    },
    async onSubmit() {
      this.payload.contact.address = await multiLingualAddress(this.address)
      this.createJob();
    },
    async createJob() {
      const { error, message } = await createJob(this.payload);
      if (error) {
          this.$toast.error(message)
        return;
      }
      this.$toast.success('Create new job is successfiul!')
      window.location.href = `/companyprofile`;
    },
    async loadJobCategoryData() {
      const { data } = await getJobCategory();
      this.jobCategories = data;
    },
  }
}
</script>

<style lang="scss" scoped>
p {
  color: #000000;
  font-weight: 500;
}
.btn {
  width: 6rem;
}
label {
  color: $primary-hard-color !important;
}
.content { 
  margin: 1rem;
  width: 100%;
  h3 {
    font: normal normal bold 40px/21px adman x;
    color: #004D8D;
    margin: 1rem 0;
    line-height: 30px;
  }
  input.form-control, textarea.form-control, label, b-form-input {
    color: #000000;
    font-size: 15px;
  }
}

.banner {
  position: relative;
  .img-fluid {
    width:100%;
    object-fit: cover;
    max-height: 300px;
  }
  .card-img-logo {
    position: absolute;
    right: 5vw;
    bottom: -5vw;
    width: 10%;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 17px;
  }
}

.input-group > .form-control {
  height: 2.5rem !important;
  margin-left: -4px !important;
  margin-right: -4px !important;
  border-radius: 0px !important;
}

</style>